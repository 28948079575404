import React from "react"

import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

const HeroSection = () => (
  <PurpleHeroSection
    titleFirst={"Digital"}
    titleSecond={"Marketing"}
    subtitle={"Your business without walls"}
    content={
      "  We create custom and all device responsive Website Design that result in higher conversions within quick turn-around time-frames"
    }
    image={require("./../assets/Layer 2.svg")}
  />
)
const GrowBusiness = () => (
  <section className="container px-5 md:px-12 pt-16 pb-48 white-dots-bg bg-cover bg-no-repeat">
    <div className="text-center md:w-9/12 mx-auto">
      <h4 className="text-pgray leading-tight text-2xl font-semibold mb-5 md:mb-3 ">
        Grow Your Business Online with Results-Driven Digital Marketing
      </h4>
      <div className="text-pgray2 text-left">
        <p>
          Whether you’re looking for individual digital marketing services
          tailored to your requirements, or to outsource all of your digital
          marketing needs, we have the solution to increase your sales or leads.
        </p>
        <p className="mt-5">
          Digital marketing helps your business to attract new custom, convert
          website traffic into sales, build your brand and strengthen your
          customer base. We specialise in creating digital marketing strategies
          and plans which generate measurable results.
        </p>
      </div>
    </div>
  </section>
)
const OurServices = () => {
  const data = [
    {
      title: (
        <>
          <img
            className="h-20"
            alt={""}
            src={require("./../assets/dashboard (2).svg")}
          />
          <br />
          Search engine optimisation
        </>
      ),
      content:
        "An effective search engine strategy makes sure that your website ranks highly in search engine results - and ahead of your competitors.",
    },
    {
      title: (
        <>
          <img
            className="h-20"
            alt={""}
            src={require("./../assets/ppc (1).svg")}
          />
          <br />
          Pay per click marketing
        </>
      ),
      content:
        "Paid search is a highly effective way of reaching your customers just when they are looking for products and services like yours. Touchcore makes sure your business is in front of your target audience when they start their search.",
    },
    {
      title: (
        <>
          <img
            className="h-20"
            alt={""}
            src={require("./../assets/email.svg")}
          />
          <br />
          Email Marketing
        </>
      ),
      content:
        "The power of email marketing lies in the fact that your emails land directly in your customer’s inbox. From monthly newsletters to ecommerce sales emails, your campaigns can play a significant role in increasing leads and/or sales for your business.",
    },
    {
      title: (
        <>
          <img
            className="h-20"
            alt={""}
            src={require("./../assets/Outline.svg")}
          />
          <br />
          Content Marketing
        </>
      ),
      content:
        "Content marketing is at the heart of any successful digital marketing strategy. Touchcore can help shape the perception of your brand online, and turn website visitors into customers.",
    },
  ]
  return <CustomHowWeDoIt title="Our services" data={data} />
}
const DigitalMarketing = () => (
  <Layout insightCategory="digital-marketing">
    <SEO title="Digital Marketing" />
    <HeroSection />
    <GrowBusiness />
    <OurServices />
    <OtherServices />
  </Layout>
)

export default DigitalMarketing
